import React, { useImperativeHandle, useRef } from 'react'
import Slick from 'react-slick'

import { SlideshowHandler, SlideshowProps } from './types'

const ArrowButton = ({ currentSlide, slideCount, ...rest }: any) => (
  <button
    data-current-slide={currentSlide}
    data-slide-count={slideCount}
    {...rest}
  />
)

const Slideshow: React.ForwardRefRenderFunction<
  SlideshowHandler,
  SlideshowProps
> = ({ children, className, settings = {}, testId }, ref) => {
  const { slidesToShow = 5, ...rest } = settings
  const slickRef = useRef<Slick>(null)

  const options: any = {
    dots: false,
    draggable: false,
    infinite: false,
    initialSlide: 0,
    nextArrow: (
      <ArrowButton>
        <span className="icon-arrow-right" />
      </ArrowButton>
    ),
    prevArrow: (
      <ArrowButton>
        <span className="icon-arrow-left" />
      </ArrowButton>
    ),
    waitForAnimate: false,
    slidesToScroll: settings.centerMode ? 1 : slidesToShow,
    slidesToShow,
    focusOnSelect: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToScroll: 4,
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToScroll: 3,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToScroll: 2,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1,
        },
      },
    ],
    ...rest,
  }

  useImperativeHandle(ref, () => ({
    go: (index) => slickRef.current && slickRef.current.slickGoTo(index),
  }))

  const handleBeforeChange = (currentSlide: number, nextSlide: number) => {
    const countSlide = React.Children.count(children) + 1
    const firstCloneSlide = 0
    const firstSlide = 1
    const lastSlide = countSlide - 1
    const lastCloneSlide = countSlide
    const state: any =
      slickRef.current &&
      (slickRef.current.state as {
        breakpoint: number
      })
    const option: any = options.responsive.find(
      (option: any) => option.breakpoint === state.breakpoint
    )
    const slidesToScroll: any =
      option && option.settings !== 'unslick'
        ? option.settings.slidesToScroll
        : slickRef.current && slickRef.current.props.slidesToScroll

    if (slickRef.current) {
      if (nextSlide === firstCloneSlide) {
        if (currentSlide === firstSlide) {
          slickRef.current.slickGoTo(lastSlide - (slidesToScroll - 1))
        } else {
          slickRef.current.slickGoTo(firstSlide)
        }
      } else if (nextSlide + slidesToScroll > lastCloneSlide) {
        if (currentSlide === lastSlide - (slidesToScroll - 1)) {
          slickRef.current.slickGoTo(firstSlide)
        } else {
          slickRef.current.slickGoTo(lastSlide - (slidesToScroll - 1))
        }
      }

      if (currentSlide === nextSlide) {
        slickRef.current.slickNext()
      }
    }

    if (rest.beforeChange) {
      rest.beforeChange(currentSlide, nextSlide)
    }
  }

  if (!options.infinite) {
    options.initialSlide = 1
    options.beforeChange = handleBeforeChange
  }

  if (rest.responsive && !rest.centerMode) {
    options.responsive = rest.responsive.map((device) => {
      if (device.settings !== 'unslick') {
        return {
          breakpoint: device.breakpoint,
          settings: {
            ...device.settings,
            slidesToScroll: device.settings.slidesToShow,
            slidesToShow: device.settings.slidesToShow,
          },
        }
      }
    })
  }

  return (
    <div
      className={['slideshow', className].filter((x) => x).join(' ')}
      data-testid={testId}
    >
      <Slick ref={slickRef} {...options}>
        {options.infinite ? null : (
          <div className="slick-slide-target slick-slide-clone">
            {children[React.Children.count(children) - 1]}
          </div>
        )}
        {React.Children.map(children, (child, index) => (
          <div key={index} className="slick-slide-target">
            {child}
          </div>
        ))}
        {options.infinite ? null : (
          <div className="slick-slide-target slick-slide-clone">
            {children[0]}
          </div>
        )}
      </Slick>
    </div>
  )
}

export default React.forwardRef(Slideshow)
