import { useApp } from '@/core/contexts/app'
import IconList, { IconListItem } from '@/components/base/icon-list'

import { SocialMediaShareProps } from './types'

const SocialMediaShare: React.FunctionComponent<SocialMediaShareProps> = ({
  className,
}) => {
  const app = useApp()

  return (
    <IconList className={className}>
      <IconListItem
        icon={{
          name: 'icon-facebook',
          style: {
            cursor: 'pointer',
          },
          onClick: (event) => {
            event.preventDefault()

            window.open(
              `https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}/${app.page.path}&quote=${app.page.title}`,
              '_blank'
            )
          },
        }}
      />
      <IconListItem
        icon={{
          name: 'icon-twitter',
          style: {
            cursor: 'pointer',
          },
          onClick: (event) => {
            event.preventDefault()

            window.open(
              `https://twitter.com/intent/tweet?text=${app.page.title}&url=${window.location.origin}/${app.page.path}`,
              '_blank'
            )
          },
        }}
      />
      <IconListItem
        icon={{
          name: 'icon-whatsapp-filled',
          style: {
            cursor: 'pointer',
          },
          onClick: (event) => {
            event.preventDefault()

            window.open(
              `https://wa.me/?text=${app.page.title} - ${window.location.origin}/${app.page.path}`,
              '_blank'
            )
          },
        }}
      />
    </IconList>
  )
}

export default SocialMediaShare
