import Anchor from '@/components/base/anchor'
import Icon from '@/components/base/icon'

import { IconListItemProps } from './types'

const IconListItem: React.FunctionComponent<IconListItemProps> = (props) => {
  const { icon, text, anchor, ...rest } = props

  const Body = () => {
    if (text) {
      return (
        <>
          <span>
            <Icon {...icon} />
          </span>
          <span>
            <span className="c-iconlist-item-label">{text}</span>
          </span>
        </>
      )
    }

    return <Icon {...icon} />
  }

  if (anchor) {
    return (
      <Anchor className="c-iconlist-item-anchor" title={text} {...anchor}>
        <Body />
      </Anchor>
    )
  }

  return (
    <div className="c-iconlist-item" {...rest}>
      <Body />
    </div>
  )
}

export default IconListItem
