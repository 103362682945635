import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'

import { EnvironmentContext } from '@/core/classes/environment'

import { Mappers } from './types'

export function request<T>(
  environment: EnvironmentContext,
  config: AxiosRequestConfig,
  mappers?: Mappers
) {
  if (mappers) {
    if (config.params && mappers.params) {
      config.params = mappers.params(config.params)
    }

    if (config.data && mappers.req) {
      config.data = mappers.req(config.data)
    }
  }

  return new Promise<T>((resolve, reject) => {
    axios
      .request({
        baseURL: environment.service,
        ...config,
      })
      .then((res: AxiosResponse) => {
        if (mappers && mappers.res) {
          try {
            resolve(mappers.res(res.data))
          } catch (err: any) {
            reject({
              code: 406,
              type: err.name,
              message: err.message,
            })
          }
        } else {
          resolve(res.data)
        }
      })
      .catch((err: AxiosError) => {
        if (err.response) {
          reject({
            code: err.response.status,
            type: err.response.statusText,
            message: err.message,
          })
        } else {
          reject({
            code: 503,
            type: 'Service Unavailable',
            message: 'The server cannot be reached.',
          })
        }
      })
  })
}
