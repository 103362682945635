import React from 'react'

import type { IconButtonProps } from './types'
import Button from '@/components/base/button'

const IconButton: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  IconButtonProps
> = ({ children, ...rest }, ref) => {
  if (React.isValidElement(children)) {
    return (
      <Button
        className="button--icon"
        iconBefore={children}
        ref={ref}
        {...rest}
      />
    )
  }

  return null
}

export default React.forwardRef(IconButton)
