import { request } from '../http'

import { EnvironmentContext } from '@/core/classes/environment'

import type { Query, QueryParams } from './types'

export const run = <T>(environment: EnvironmentContext, params: QueryParams) =>
  request<Query<T>>(environment, {
    method: 'get',
    url: `/queries/${params.language}/${params.name}/run?${params.query || ''}`,
  })

export const runForm = <T>(
  environment: EnvironmentContext,
  params: QueryParams,
  data: any
) =>
  request<Query<T>>(environment, {
    method: 'post',
    url: `/queries/${params.language}/${params.name}/run-form`,
    // headers: {
    //   'Content-Type': 'multipart/form-data',
    // },
    data,
  })
