import React, { useState } from 'react'
import { classnames } from 'begonya/functions'

import { useApp } from '@/core/contexts/app'
import { useMobile, useTablet } from '@/core/hooks'
import Image from '@/components/base/image'
import Anchor from '@/components/base/anchor'
import { path } from '@/core/utils'

import { GoTurkiyeImageProps } from './types'

const GoTurkiyeImage: React.FunctionComponent<GoTurkiyeImageProps> = (
  props
) => {
  const {
    alt,
    anchor,
    isSustainability,
    isRotated,
    shadows = {},
    image,
    imageMedium,
    imageSmall,
    sustainabilityDescription,
    sustainabilityTitle,
    title,
    ...rest
  } = props

  const app = useApp()
  const isMobile = useMobile(app.headers['user-agent'])
  const isTablet = useTablet(app.headers['user-agent'])
  const [isPopup, setIsPopup] = useState(false)

  const getFileName = () => {
    if (isTablet && imageMedium) {
      return path.asset(app.environment, imageMedium.src || '')
    } else if (isMobile && imageSmall) {
      return path.asset(app.environment, imageSmall.src || '')
    }

    return image && path.asset(app.environment, image.src || '')
  }

  const renderSustainability = () => {
    if (!isSustainability || isRotated) return null

    const handlePopup = (event: React.MouseEvent) => {
      event.preventDefault()

      setIsPopup(!isPopup)
    }

    return (
      <div className="image-sustainability">
        <Image
          src="/icons/icon-sustainability.svg"
          alt="GoTürkiye Sustainability"
          onClick={handlePopup}
        />
        {sustainabilityDescription && (
          <Anchor onClick={handlePopup}>
            <div
              className={classnames(
                'image-sustainability-caption',
                isPopup && 'image-sustainability-caption--active'
              )}
            >
              <strong>{sustainabilityTitle}</strong>
              <p>{sustainabilityDescription}</p>
            </div>
          </Anchor>
        )}
      </div>
    )
  }

  if (getFileName()) {
    const Content = () => (
      <React.Fragment>
        <Image
          className="image"
          src={getFileName()}
          alt={title || alt}
          {...rest}
        />
        {renderSustainability()}
      </React.Fragment>
    )

    if (anchor) {
      return (
        <Anchor style={{ display: 'block' }} {...anchor}>
          <Content />
        </Anchor>
      )
    }

    return <Content />
  }

  return null
}

export default GoTurkiyeImage
