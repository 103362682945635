import IconListItem from './IconListItem'

import { IconListProps } from './types'

const IconList: React.FunctionComponent<IconListProps> = (props) => {
  const {
    align = 'horizontal',
    children,
    className,
    data = [],
    ...rest
  } = props

  return (
    <div
      className={['c-icon-list', `c-icon-list-${align}`, className]
        .filter((item) => item)
        .join(' ')}
      {...rest}
    >
      {data.map((icon, index) => (
        <IconListItem key={index} {...icon} />
      ))}
      {children}
    </div>
  )
}

export default IconList
