import React, { useEffect, useRef, useState } from 'react'
import Link from 'next/link'

import { useApp } from '@/core/contexts/app'
import { path } from '@/core/utils'
import { useCombineRefs } from '@/core/hooks'

import type { AnchorProps } from './types'

const Anchor: React.ForwardRefRenderFunction<HTMLAnchorElement, AnchorProps> = (
  { children, href, ...rest },
  ref
) => {
  const app = useApp()
  const anchorRef = useRef<HTMLAnchorElement>(null)
  const refs = useCombineRefs<HTMLAnchorElement>(anchorRef, ref)
  const url = path.app(app.environment, href)

  const [test, setTest] = useState(false)

  useEffect(() => {
    if (window.location.pathname === url) {
      anchorRef.current?.setAttribute('aria-current', 'page')
    }
  }, [url])

  if (url.indexOf('http') === 0) {
    return (
      <a
        ref={refs}
        href={url}
        target="_blank"
        rel="noreferrer noopener"
        onMouseEnter={() => setTest(true)}
        onMouseLeave={() => setTest(false)}
        {...rest}
      >
        {children}
      </a>
    )
  }

  return (
    <Link href={url}>
      <a ref={refs} {...rest}>
        {children}
      </a>
    </Link>
  )
}

export default React.forwardRef(Anchor)
